import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import classNames from 'classnames'

import { StoreContext } from '@/store'

import Icon, { ICONS_TYPES } from '@/components/Icon'

import styles from './index.module.css'

const Header = ({
  className,
}) => {
  const navigate = useNavigate()
  const store = useContext(StoreContext)

  const { authStore } = store

  return (
    <div className={classNames(className, styles.root)}>
      <div className={styles.logo}>LOGO</div>
      <div className={styles.menu}>
        <NavLink
          to="/users"
          className={({ isActive }) => (isActive ? classNames(styles.link, styles.active) : styles.link)}
        >
          Пользователи
        </NavLink>
        <NavLink
          to="/divisions"
          className={({ isActive }) => (isActive ? classNames(styles.link, styles.active) : styles.link)}
        >
          Подразделения
        </NavLink>
      </div>
      <Icon
        className={styles.logout}
        type={ICONS_TYPES.logout}
        size={40}
        onClick={() => authStore.logout(navigate)}
      />
    </div>
  )
}

export default observer(Header)
